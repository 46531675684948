import React from 'react'
import { Link } from 'react-router-dom'


const Lip_Fillers_101_Everything_You_Need_to_Know_for_Perfect_Lips = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Lip Fillers 101: Everything You Need to Know for Perfect Lips</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-3 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Lip Fillers 101: Everything You Need to Know for Perfect Lips</span>
                        </h1>
                        <p>At <a href='https://drnetisclinic.com/'>Netis Skin, Hair, and Nail Clinic,</a> achieving the perfect pout is simple and safe with our expert lip filler treatments. Lip fillers are a non-surgical cosmetic procedure designed to enhance the shape, size, and symmetry of your lips, giving you the confidence to flaunt a fuller smile.</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>What Are Lip Fillers?</span>
                        </h4>
                        <p>Lip fillers are made of hyaluronic acid, a naturally occurring substance in the body that adds volume and hydration. Our skilled practitioners use premium fillers to create natural-looking results tailored to your preferences.</p>

                        


                    </div>
                    <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '250px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='Lip-Filler-101.jpg' alt='Lip Filler 101 perfect lips(Netis Skin hair and nail)'></img>
                    </div>
                </div>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>The Procedure</span>
                        </h4>

                        <p>The treatment typically takes 20–30 minutes. After a thorough consultation to understand your goals, a numbing cream is applied to minimize discomfort. The filler is then carefully injected into specific areas of your lips to enhance volume, contour, and definition.</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Benefits of Lip Fillers</span>
                        </h4>
                        <ul>
                            <li>Instant Results: Noticeable enhancement immediately after the procedure.</li>
                            <li>Minimal Downtime: You can resume your daily activities right away.</li>
                            <li>Customizable: Adjusted to your desired look—whether subtle or bold.</li>
                        </ul>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Aftercare Tips</span>
                        </h4>

                        <ul>
                            <li>Avoid touching or massaging your lips for 24 hours.</li>
                            <li>Stay hydrated and avoid alcohol for a day.</li>
                            <li>Use cold compresses to reduce swelling if needed.</li>
                        </ul>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Why Choose Netis Clinic?</span>
                        </h4>
                        <p>Our experts prioritize safety, precision, and your satisfaction. Every treatment is personalized, ensuring you leave with lips that look natural and beautiful.</p>
                       
               
            </div>
        </>
    )
}

export default Lip_Fillers_101_Everything_You_Need_to_Know_for_Perfect_Lips