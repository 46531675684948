import React from 'react'
import { Link } from 'react-router-dom'


const Top_10_Skin_Care_Tips_From_Dr_Sai_Prasanth_Netis = () => {
    return (
        <>

            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Top 10 Skin Care Tips From Dr Sai Prasanth Neti</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Top 10 Skin Care Tips From Dr Sai Prasanth Neti</span>
                        </h1>
                        <p>
                            Did you know that your skin sheds about 40,000 skin cells every day? As the largest organ in your body, your skin is a protective barrier that continuously replenishes itself. It not only regulates your temperature, but also shields you from disease, infection, the sun and other atmospheric elements so you can remain healthy, strong and active.
                        </p>
                        <p>
                            That’s why taking care of your skin is so important. Before you set out to start a new skin care routine (or before you throw on another layer of cucumber melon lotion), dermatologist Dr Sai Prasanth Neti, shares her top 10 tips for taking care of your skin.
                        </p>

                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Skin care tips recommended by a dermatologist</span>
                        </h4 >
                        <p>The largest organ in your body demands a lot of attention, so it’s no wonder there are a ton of misconceptions about which skin care products to use, how you should care for your skin and what routines work best. Here are 10 things to consider when taking care of your skin.</p>
                    </div>
                    <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='TopSkinCare.png' alt=''></img>
                    </div>
                </div>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>1. Wear sunscreen every day</span>
                </h4>
                <p>It doesn’t matter where you’re from, you’re never truly safe from the sun. The sun’s rays not only dry out your skin, but they can significantly impact the pigment in your skin, too.
                    “On a snowy day or a cloudy day, there’s only 20% less UV rays than what you would get on a sunny day,” says Dr Sai Prasanth Neti. “Even when you’re driving or sitting next to a window, that visible light can dull our skin.”
                </p>
                <p>Dr Sai Prasanth Neti recommends wearing sunscreen of 30 SPF or higher every day. There are different types of sunscreens — a physical kind that 100% reflects the sun, and a chemical kind that absorbs UV rays into your skin and then breaks it up. Dr Sai Prasanth Neti recommends using the physical kind whenever possible because it’s 100% effective.</p>
                <p>Make sure you also check the expiration date, as sunscreen loses its protective properties when it’s expired. You want to apply 1 ounce (the size of a shot glass) every two hours, and then reapply again every two hours to stay protected and avoid sunburns.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>2. Check your skin for cancer every month</span>
                </h4 >
                <p>Skin cancers are on the rise. According to the American Cancer Society, skin cancer is the most commonly diagnosed cancer in the U.S., with more than 5 million new cases diagnosed every year.</p>
                <p>“Recent studies estimate 1 in 5 Americans will have skin cancer during their lifetime,” notes Dr Sai Prasanth Neti. “A majority of those cancers are sun-related.”</p>
                <p>Skin cancers can also be exacerbated by tanning, so Dr Sai Prasanth Neti warns against certain misconceptions:</p>
                <ul>
                    <li>You don’t need a base tan before vacation. Some believe you should tan before you spend a day out in the sun to avoid burning, but this isn’t actually true. “Every visit to the tanning bed increases your risk of melanoma four times,” says Dr Sai Prasanth Neti. “If you want a tan, get a spray tan.”
                    </li>
                    <li>You’ll still get a tan if you use sunscreen. A lot of times, someone will forego sunscreen because they’re worried about a tan, but the truth is you’ll still get a tan with sunscreen, you just won’t burn.
                    </li>
                </ul>
                <p>“About 60% of our sun exposure happens before the age of 18. So, most of the damage that’s been done has been done since childhood,” says Dr Sai Prasanth Neti. “Because of this, we recommend monthly self-scanning exams.”</p>
                <p>If you’re older than 30, you shouldn’t be developing any new moles, so check once a month to see if you spot any new ones. If you have existing moles, birthmarks or skin conditions, you’ll want to follow this checklist for signs of melanoma:</p>
                <ul>
                    <li>Asymmetry: One half doesn’t match the other half.</li>
                    <li>Border: The edges aren’t smooth.</li>
                    <li>Color: The color is mottled and uneven, with shades of brown, black, gray, red or white.</li>
                    <li>Diameter: The spot is greater than the tip of a pencil eraser (6.0 millimeters).</li>
                    <li>Evolving: The spot is new or changing in size, shape or color.</li>
                </ul>
                <p>“Any one of these is concerning, but if you have more than one of these features, that’s a sign there could be something more going on and it should be checked out,” stresses Dr Sai Prasanth Neti. “Any existing mole or lesion that is itching, bleeding or growing should be evaluated.”</p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>3. Moisturize your skin</span>
                </h4 >
                <p>As we get older, the oil glands in our skin become less active so our skin dries out, becomes sensitive and is more easily prone to irritation. Moisturizer is meant to pick up the slack and keep your skin soft and healthy. Dr Sai Prasanth Neti recommends using a moisturizer within three minutes after getting out of the shower to lock in the water and keep your skin hydrated.</p>
                <p>“If someone never uses a moisturizer, their skin cells are going to be like raisins,” says Dr Sai Prasanth Neti. “When they moisturize, they’ll be more like a grape and not be as wrinkly or dry.”</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>4. Stop smoking</span>
                </h4 >
                <p>Beyond your heart and lungs, smoking can take a toll on your skin. If you don’t quit smoking, tobacco can cause stress to your skin cells and your blood vessels to constrict.</p>
                <p>“The areas where blood vessels supply blood to our skin shrink over time, which can lead to more wrinkling, premature aging and delayed healing in the skin,” explains Dr. Sai Prasanth Neti. “For people who smoke, they’re more prone to infection because it takes them longer to heal.”</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>5. Acne isn’t a hygiene problem</span>
                </h4 >
                <p>If you have acne and think you’re not washing your face enough, you might want to reconsider how you think about your routine.</p>
                <p>“Acne is largely genetic or hormonally driven,” says Dr Sai Prasanth Neti. “It has nothing to do with your personal hygiene or how often you wash your face.”
                    You should use benzoyl peroxide to kill the bacteria that cause acne and use adapalene, a retinoid, to keep your pores clean and clear.</p>



                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>6. Don’t pop your pimples</span>
                </h4 >
                <p>Yes, you read that correctly. At the first sign of a pimple on your face, you might be in a hurry to pop it or squeeze it to get it to go away, but you have to fight that urge.</p>
                <p>“If you pop it, you could introduce an infection and make inflammation worse, which could lead to more scarring,” Dr Sai Prasanth Neti cautions. “The best thing you can do is leave pimples alone and apply a topical benzoyl peroxide cream to spot-treat it.”</p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>7. Use a daily topical antioxidant</span>
                </h4 >
                <p>Whether we’re inside of our homes or outside, we expose our bodies to a number of pollutants every day. A comprehensive review of these pollutants shows that organic compounds, heavy metals, gaseous pollutants and components from the Earth’s ozone layer directly impact the function of our skin barrier. These pollutants can worsen skin conditions such as aging, acne, hair loss, inflammatory diseases like eczema or psoriasis, and even skin cancers. Because of this, Dr Sai Prasanth Neti suggests using a topical antioxidant cream, serum or gel every day.</p>
                <p>“Using a topical antioxidant like vitamin C or vitamin E protects our skin from all that oxidative damage,” says Dr Sai Prasanth Neti.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>8. Stay away from products with fragrance</span>
                </h4 >
                <p>We love to smell good, and those annual sales on floral bath and body products get us every time. But fragrance can actually be harmful because it pulls moisture out of your skin and makes you more prone to allergies, irritation and dryness.</p>

                <p>“A lot of moisturizers are so full of fragrance, that you’re trying to add moisture but the fragrance is counteracting it,” says Dr Sai Prasanth Neti.
                    She suggests relying on fragrance-free products whenever possible — including laundry detergent, dryer sheets and anything you’d put on your body other than natural soap. But if you can’t break away from products that use fragrance, you should do a use test. Put a small sample on your body and see whether or not you have a negative or allergic reaction in a 24-hour period.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>9. Avoid using oil on your scalp</span>
                </h4 >
                <p>Using essential oils like tea tree oil and carrier oils like argan oil has been trendy for a while now. Although they have a variety of benefits that include treating wounds and reducing inflammation, it’s best not to use these directly on your scalp.</p>
                <p>“A lot of oils hold a fragrance and oils don’t actually repair our skin barrier,” says Dr Sai Prasanth Neti. “They just sit on the surface of the skin and actually make dandruff, acne and rosacea worse.”</p>
                <p>If you’re going to use oil, use it on the ends of your hair instead of rubbing it into your scalp.</p>
                
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>10. Use barebone ingredients</span>

                </h4 >
                <p>There are a ton of skin care products on the market, so it can be hard to figure out which products to use and which ingredients are best for you. At the end of the day, the golden rule is to rely on gentle skin care routines that use the least amount of ingredients. Those ingredients should also be natural.</p>
                <p>“Without fragrance, extra preservatives and all these other things, you want to use bare-bone ingredients to serve a purpose — whether it’s moisturizing, cleansing or using sunscreen,” says Dr Sai Prasanth Neti.</p>
                <p>And if you have a skin condition that flares up along the way, try cutting back on the items you’re using to see if you can suss out the culprit.</p>
                <p>“If you’ve tried simple measures and eliminated products you might be allergic to, if it’s not improving despite that, you should seek medical attention from a <a href='https://drnetisclinic.com/'>dermatologist</a>,” advises Dr Sai Prasanth Neti.</p>
           </div>
        </>
    )
}

export default Top_10_Skin_Care_Tips_From_Dr_Sai_Prasanth_Netis