import React from 'react'
import { Link } from 'react-router-dom'


const Eight_Key_Benifits_Of_Laser_Toning = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>8 key benifits of laser toning</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>8 key benifits of laser toning</span>
                        </h1>
                        <p>Laser toning, commonly used in dermatology and cosmetic procedures, offers multiple benefits for skin rejuvenation and addressing various skin concerns. Here are eight key benefits of laser toning:</p>
                        <ol>
                            <li><b>Improves Skin Texture: </b>Laser toning helps reduce roughness and enhances the smoothness of the skin by stimulating collagen production.</li>
                            <li><b>Reduces Pigmentation: </b>It effectively targets and reduces hyperpigmentation, sun spots, and melasma, leading to a more even skin tone.</li>
                            <li><b>Minimizes Pores: </b>Laser toning tightens the skin, which helps minimize the appearance of large pores, giving the skin a more refined look.</li>
                            <li><b>Treats Acne Scars: </b>It helps in the reduction of acne scars by promoting the skin’s healing process, leading to smoother skin.</li>
                            <li><b>Stimulates Collagen Production: </b>Laser toning stimulates collagen synthesis, which helps improve skin elasticity and reduces fine lines and wrinkles.</li>
                            <li><b>Non-invasive and Painless: </b>The procedure is non-invasive and relatively painless compared to more aggressive laser treatments, making it suitable for people with sensitive skin.</li>
                            <li><b>Improves Overall Skin Tone: </b>It brightens the complexion, leaving the skin looking more radiant and rejuvenated.</li>
                            <li><b>Quick Recovery Time: </b>The procedure involves minimal downtime, and patients can typically resume their daily activities immediately after treatment.</li>
                        </ol>
                        <p>Dr Sai Prasanth Neti is <a href='https://drnetisclinic.com/'>best lazer toning treatment doctor</a></p>

                    </div>
                    <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='Laser-Toning1.png' alt=''></img>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Eight_Key_Benifits_Of_Laser_Toning