import React from 'react'
import { Link } from 'react-router-dom'


const Eight_Best_Hair_Growth_Supplements_That_Youre_Unaware_Of = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>8 Best Hair Growth Supplements That You’re Unaware Of</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-3 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <p>Hair growth supplements can be an effective way to address thinning hair, promote hair growth, and improve overall hair health. Here are eight hair growth supplements that you might not be aware of, but are worth considering:</p>

                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>1. Viviscal</span>
                        </h4 >
                        <ul>
                            <li>Ingredients: AminoMar marine complex, Biotin, Zinc, Vitamin C.</li>
                            <li>Benefits: Supports existing hair growth, promotes the health of hair follicles, and can improve hair thickness.</li>
                        </ul>

                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>2. Nutrafol</span>
                        </h4 >
                        <ul>
                            <li>Ingredients: Ashwagandha, Marine Collagen, Biotin, Keratin, and Hyaluronic Acid.</li>
                            <li>Benefits: Targets multiple root causes of hair thinning, including stress, hormonal imbalance, and nutrition, to promote thicker hair growth.</li>
                        </ul>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>3. Hair La Vie Clinical Formula</span>
                        </h4 >
                        <ul>
                            <li>Ingredients: Reishi Mushroom, Saw Palmetto, Biotin, Hydrolyzed Collagen.</li>
                            <li>Benefits: Promotes hair growth, strengthens hair strands, and supports scalp health.</li>
                        </ul>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>4. SugarBearHair Vitamins</span>
                        </h4 >
                        <ul>
                            <li>Ingredients: Biotin, Folic Acid, Vitamin D, Zinc.</li>
                            <li>Benefits: A tasty gummy supplement that helps promote hair strength, elasticity, and overall health.</li>
                        </ul>

                    </div>
                    <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='hair grouth.png' alt=''></img>
                    </div>
                </div>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>5. Nutrafol Women Balance</span>
                </h4 >
                <ul>
                    <li>Ingredients: Saw Palmetto, Maca, and Sensoril Ashwagandha.</li>
                    <li>Benefits: Designed specifically for women going through menopause, it balances hormones to promote healthy hair growth.</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>6. Ouai Thinning Hair Supplements</span>
                </h4 >
                <ul>
                    <li>Ingredients: Ashwagandha, Biotin, Omega-3 Fatty Acids, Silica.</li>
                    <li>Benefits: Helps reduce hair shedding, increases hair growth, and improves overall hair health</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>7. Hairfinity Healthy Hair Vitamins</span>
                </h4 >
                <ul>
                    <li>Ingredients: Biotin, Niacin, Vitamin A, Vitamin D, B-Vitamins, Capilsana Complex.</li>
                    <li>Benefits: Strengthens hair, improves elasticity, and reduces breakage.</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>8. Hum Nutrition Hair Sweet Hair</span>
                </h4 >
                <ul>
                    <li>Ingredients: Biotin, Zinc, Fo-Ti, PABA (para-aminobenzoic acid).</li>
                    <li>Benefits: A vegan gummy supplement that supports healthy hair growth, boosts shine, and promotes overall hair wellness.</li>
                </ul>
                <p>Dr Sai Prasanth Neti <a href='https://drnetisclinic.com/'>best hair treatment doctor</a></p>

            </div>
        </>
    )
}

export default Eight_Best_Hair_Growth_Supplements_That_Youre_Unaware_Of