import React from 'react'
import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import emailjs from 'emailjs-com';

const Header = () => {
  // Book Appointment

  const form = useRef()
  const [data, updateData] = useState({
    Name: "",
    Email: '',
    Number: '',
    Subject: '',
    Message: ''
  })

  const { Name, Email, Number, Subject, Message } = data;

  const changeHandler = e => {
    updateData({ ...data, [e.target.name]: [e.target.value] })

  }
  const submitHandler = e => {
    e.preventDefault();

    emailjs.sendForm('service_e4unsfa', 'template_i2qlv5d', e.target, 'x9kx9l2tKiJht888k')
      .then((result) => {
        alert("Successfully Booked Your Appointment ")
        updateData({
          Name: "",
          Email: "",
          Number: "",
          Subject: '',
          Message: ''

        })

      }, (error) => {
        console.log(error.text);
      });

  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // without scrolling links'
  const [home, sethome] = useState(false);
  const [about, setabout] = useState(false);
  const [contact, setcontact] = useState(false);
  const [Acne,setAcne] = useState(false);
  const [Pigmentation,setPigmentation] = useState(false);
  const [SkinWhitening,setSkinWhitening] = useState(false);
  const [ChemicalPeel,setChemicalPeel] = useState(false);
  const [Hydradermabrasion,setHydradermabrasion] = useState(false);
  const [SkinRejuvenation,setSkinRejuvenation] = useState(false);
  const [StretchMarks,setStretchMarks] = useState(false);
  const [Carbon,setCarbon] = useState(false);
  const [LaserHairRemoval,setLaserHairRemoval] = useState(false);
  const [LaserTattoo,setLaserTattoo] = useState(false);
  const [Wrinkles,setWrinkles] = useState(false);
  const [Growthfactor,setGrowthfactor] = useState(false);
  const [CornWart,setCornWart] = useState(false);
  const [PrpFor,setPrpFor] = useState(false);
  const [Permanent,setPermanent] = useState(false);
  const [LaserToning,setLaserToning] = useState(false);
  const [NailSurgery, setNailSurgery] = useState(false);
  const [Earlobe, setEarlobe] = useState(false);
  const [Antiaging, setAntiaging] = useState(false);
  const [HairTransplantation, setHairTransplantation] = useState(false);
  const [Scalp, setScalp] = useState(false);
  const [Vitiligo, setVitiligo] = useState(false);


  if (about) {
    return <div>
      <Header />
      <return to='/about' />
    </div>
  }

  if (contact) {
    return <div>
      <Header />
      <return to='/contact' />
    </div>
  }

 
  if (home) {
    return <div>
      <Header />
      <return to='/Home' />
    </div>
  }
  if (Acne) {
    return <div>
      <Header />
      <return to='/Acne_And_Acne_Scars_Treatment' />
    </div>
  }
  if (Pigmentation) {
    return <div>
      <Header />
      <return to='/Pigmentation_Treatment' />
    </div>
  }
  if (SkinWhitening) {
    return <div>
      <Header />
      <return to='/Skin_Whitening_Treatment' />
    </div>
  }
  if (ChemicalPeel) {
    return <div>
      <Header />
      <return to='/Chemical_Peel_Treatment' />
    </div>
  }
  if (Hydradermabrasion) {
    return <div>
      <Header />
      <return to='/Hydradermabrasion_Treatment' />
    </div>
  }
  if (SkinRejuvenation) {
    return <div>
      <Header />
      <return to='/Skin_Rejuvenation' />
    </div>
  }
  if (StretchMarks) {
    return <div>
      <Header />
      <return to='/Stretch_Marks_Treatment' />
    </div>
  }
  if (Carbon) {
    return <div>
      <Header />
      <return to='/Carbon_Toning' />
    </div>
  }
  if (LaserHairRemoval) {
    return <div>
      <Header />
      <return to='/Laser_Hair_Removal' />
    </div>
  }
  if (LaserTattoo) {
    return <div>
      <Header />
      <return to='/Laser_Tattoo_Removal' />
    </div>
  }
  if (Wrinkles) {
    return <div>
      <Header />
      <return to='/Wrinkles_Treatment' />
    </div>
  }
  if (Growthfactor) {
    return <div>
      <Header />
      <return to='/Growth_factor_concentrate_therapy' />
    </div>
  }
  if (CornWart) {
    return <div>
      <Header />
      <return to='/Corn_Wart_Skin_Tag_Removal_by_RF' />
    </div>
  }
  if (PrpFor) {
    return <div>
      <Header />
      <return to='/Prp_For_Face_And_Hair' />
    </div>
  }
  if (Permanent) {
    return <div>
      <Header />
      <return to='/Permanent_Eyebrows' />
    </div>
  }
  if (LaserToning) {
    return <div>
      <Header />
      <return to='/Laser_Toning' />
    </div>
  }
  if (NailSurgery) {
    return <div>
      <Header />
      <return to='/Nail_Surgery' />
    </div>
  }
  if (Earlobe) {
    return <div>
      <Header />
      <return to='/Earlobe_Repair' />
    </div>
  }
  if (Antiaging) {
    return <div>
      <Header />
      <return to='/Antiaging_Treatments' />
    </div>
  }
  if (HairTransplantation) {
    return <div>
      <Header />
      <return to='/Hair_Transplantation' />
    </div>
  }
  if (Scalp) {
    return <div>
      <Header />
      <return to='/Scalp_Micropigmentation' />
    </div>
  }
  
  if (Vitiligo) {
    return <div>
      <Header />
      <return to='/Vitiligo_Surgeries' />
    </div>
  }

  return (
    <>
      <nav>
        <div className='iconss' sticky="top">
          <div className='row'>
            <div className='col-md-6 text-center'>
              {/* <a href="#" ><button className="btn" onClick={handleShow} style={{ color: 'white', border: 'none', textAlign: 'center', textDecoration: 'none', display: ' inline-block', fontSize: '16px', backgroundColor: 'rgb(245, 41, 41)' }}>Book Appointment</button></a> */}
              {/* <div  className="dropdown" >
              <button className="dropbtn" style={{marginRight:'30px'}}>Appointment</button>
              <div className="dropdown-content " >
                <a  href="tel:9000 10">link1</a>
                <a  href="tel:70326 " style={{marginLeft:'-100px'}}>link2</a>
                
              </div>
              </div> */}
              {/* <a href=''><i class="bi bi-phone-flip">Emergency Call</i></a> */}
              <div className="dropdown" >
                <a href="tel:7995233710"><button className="dropbtn mt-1">Call : 7995233710</button></a>
                {/* <div className="dropdown-content " >
              <a  href="tel:9000 1">link1</a>
                <a  href="tel:70326" style={{marginLeft:'-100px'}}>link2</a>
                
              </div> */}
              </div>

            </div>
            <div className='col-md-6 text-center mt-1' style={{ justifyContent: 'center' }}>
              <Link to='https://www.facebook.com/drnetisskinhairandnailclinic/' title='Facebook'><i className="bi bi-facebook"></i></Link>
              <Link to='/' title='Twitter'><i className="bi bi-twitter"></i></Link>
              <Link to='https://www.youtube.com/@Drnetisskinhairandnailclinic' title='Youtube'><i className="bi bi-youtube"></i></Link>
              <Link to='https://www.instagram.com/drnetisskinclinic?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==' title='Instagram' ><i className="bi bi-instagram"></i></Link>
              <Link to='Netis_Skin_And_Hair_Clinic_Products' title='cart'><i className="bi bi-cart4"></i>  Cart</Link>
            </div>
          </div>
        </div>
        <div className="wrapper" sticky="top" style={{ backgroundColor: 'white', boxShadow: 'rgba(50, 50, 93, 0.3) 0px 30px 30px 10px' }} >
          {/* <div className="logo"><Link to="/Home"><img src='logoNatis1.png' alt='' ></img></Link></div> */}
          <div className="logo1"><Link to="/Home"><img  src='horizantal logo.png' alt='' ></img></Link></div>

          <input type="radio" name="slider" placeholder='null' title='menu' id="menu-btn" />
          <input type="radio" name="slider" placeholder='null' title='close' id="close-btn" />
          <ul className="nav-links">
            <label htmlFor="close-btn" style={{ color: 'black', width: '20px' }} className="btn close-btn"><i className="bi bi-x-circle"></i></label>
            <li className='mt-2'><Link to="/Home" onClick={()=>sethome(true)}>Home</Link></li>
            {/* <li>
              <Link to="#" className="desktop-item" onClick={()=>setabout(true)}>About</Link>
              <input type="checkbox" id="showDrop" />
              <label htmlFor="showDrop" className="mobile-item" style={{ color: 'black' }}>About</label>
              <ul className="drop-menu">
                <li><Link to="Dr_Bharani_Rachagulla" >Link</Link></li>
                <li><Link to="Why_ChooseUs" >Why ChooseUs</Link></li>

              </ul>
            </li> */}
            <li className='mt-2'><Link to="/Dr_Sai_Prasanth_Neti" onClick={()=>sethome(true)}>About</Link></li>
            <li className='mt-2'><Link to="/Gallery">Gallery</Link></li>
            <li className='mt-2'><Link to="/Blogs">Blogs</Link></li>
            {/* <li className='mt-2'>
              <Link href="#" className="desktop-items mt-2" >Blog</Link>
              <input type="checkbox" id="showDrops" />
              <label htmlFor="showDrops" className="mobile-item" style={{ color: 'black' }}>Blog</label>
              <ul className="drop-menus">
                <li><Link to="#">Drop menu 1</Link></li>
                <li><Link to="#">Drop menu 2</Link></li>
                <li><Link to="#">Drop menu 3</Link></li>
                <li><Link to="#">Drop menu 4</Link></li>
              </ul>
            </li> */}
            <li className='mt-2'>
              <Link to="#" className="desktop-item" >Specialities</Link>
              <input type="checkbox" id="showMega" />
              <label htmlFor="showMega" className="mobile-item" style={{ color: 'black' }} >Specialities</label>
              <div className="mega-box">
                <div className="content">

                  <div className="row">
                    {/* <header>Design Services</header> */}
                    <ul className="mega-links">
                      <li><Link to="Acne_And_Acne_Scars_Treatment" style={{ width: '400px' }} onClick={()=>setAcne(true)}>Acne & Acne Scars Treatment</Link></li>
                      <li><Link to="Pigmentation_Treatment" style={{ width: '400px' }} onClick={()=>setPigmentation(true)}>Pigmentation Treatment</Link></li>
                      <li><Link to="Skin_Whitening_Treatment" onClick={()=>setSkinWhitening(true)}>Skin Whitening</Link></li>
                      <li><Link to="Chemical_Peel_Treatment" style={{ width: '400px' }} onClick={()=>setChemicalPeel(true)} >Chemical Peel Treatment</Link></li>
                      <li><Link to="Hydradermabrasion_Treatment" style={{ width: '400px' }} onClick={()=>setHydradermabrasion(true)} >Hydradermabrasion Treatment</Link></li>
                      <li><Link to="Skin_Rejuvenation" style={{ fontSize: '16px' }} onClick={()=>setSkinRejuvenation(true)} > Skin Rejuvenation</Link></li>
                      <li><Link to="Stretch_Marks_Treatment" style={{ fontSize: '16px' }} onClick={()=>setStretchMarks(true)} >Stretch Marks</Link></li>
                      <li><Link to="Carbon_Toning" onClick={()=>setCarbon(true)} >Carbon Toning</Link></li>
                    </ul>
                  </div>
                  <div className="row">
                    {/* <header>Email Services</header> */}
                    <ul className="mega-links">
                      {/* <li><a href="SEPSIS_">SEPSIS & CRITICAL CARE</a></li> */}

                      <li><Link to="Laser_Hair_Removal" onClick={()=>setLaserHairRemoval(true)} >Laser Hair Removal</Link></li>
                      <li><Link to="Laser_Tattoo_Removal" onClick={()=>setLaserTattoo(true)} >Laser Tattoo Removal</Link></li>
                      <li><Link to="Wrinkles_Treatment" onClick={()=>setWrinkles(true)} >Wrinkles Treatment</Link></li>
                      <li><Link to="Growth_factor_concentrate_therapy" onClick={()=>setGrowthfactor(true)} >Growth factor concentrate therapy</Link></li>
                      <li><Link to="Corn_Wart_Skin_Tag_Removal_by_RF" onClick={()=>setCornWart(true)} >Corn/Wart/Skin Tag Removal by RF</Link></li>
                      <li><Link to="Prp_For_Face_And_Hair" onClick={()=>setPrpFor(true)} >PRP For Face And Hair</Link></li>
                      <li><Link to="Permanent_Eyebrows" onClick={()=>setPermanent(true)} >Permanent Eyebrows</Link></li>
                      <li><Link to="Laser_Toning" style={{ fontSize: '15px' }} onClick={()=>setLaserToning(true)} >Laser Toning(Carbon Toning/ Hollywood Facial)</Link></li>
                    </ul>
                  </div>
                  <div className="row">
                    {/* <header>Security services</header> */}
                    <ul className="mega-linked">
                      <li><Link to="Nail_Surgery" onClick={()=>setNailSurgery(true)} >Nail Surgery</Link></li>
                      <li><Link to="Earlobe_Repair"onClick={()=>setEarlobe(true)} >Earlobe Repair</Link></li>
                      <li><Link to="Antiaging_Treatments" onClick={()=>setAntiaging(true)} >Antiaging Treatments</Link></li>
                      <li><Link to="Hair_Transplantation" onClick={()=>setHairTransplantation(true)} >Hair Transplantation</Link></li>
                      <li><Link to="Scalp_Micropigmentation" onClick={()=>setScalp(true)} >Scalp Micropigmentation</Link></li>
                      <li><Link to="Vitiligo_Surgeries" onClick={()=>setabout(true)} >Vitiligo Surgeries</Link></li>
                      <li><Link to="MNRF_Treatment" >MNRF Treatment</Link></li>
                     
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li className='mt-2'><Link to="Contact" onClick={()=>setcontact(true)} >Contact</Link></li>
            <Link to="#" ><button className="btn" onClick={handleShow} style={{ color: 'white', border: 'none', padding: '20px 30px', textAlign: 'center', textDecoration: 'none', display: ' inline-block', fontSize: '16px',marginTop:'12px', backgroundColor: '#1f1f81',width:'210px' }}>Book Appointment</button></Link>
          </ul>
          <label htmlFor="menu-btn" className="btn menu-btn" style={{ color: 'black' }}><i className="bi bi-list" style={{ blockSize: '100px' }} ></i></label>
        </div>
      </nav>

      <Modal style={{ marginTop: '50px' }} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-center' style={{ color: '#1f1f81', textAlign: 'center' }}>Book An Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={form} onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="text" value={Name} onChange={changeHandler} className="form-control" placeholder="Your Name" name="Name" required="Username is Required..." message="Username must be atmost 30 characters long..." style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="email" value={Email} onChange={changeHandler} className="form-control" placeholder="Your Email Address" name="Email" id="email" required="Email is required" data-error="Please enter your email" fdprocessedid="p2hd6j" style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="text" value={Number} onChange={changeHandler} className="form-control" name="Number" placeholder="Phone Number" id="phone_number" required="Number is Required" data-error="Please enter your number" fdprocessedid="cf7ig" style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="text" value={Subject} onChange={changeHandler} className="form-control" name="Subject" placeholder="Subject" id="msg_subject" required="Subject is Required" data-error="Please enter your subject" fdprocessedid="4mor3e" style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >

              <textarea name="Message" value={Message} onChange={changeHandler} className="form-control" id="message" cols="30" rows="3" placeholder="Type Your Message Here" required="Message is required" data-error="Write your message"></textarea>
              <div className="help-block with-errors"></div>
            </Form.Group>
            <button type="submit" className="btn " name="contact" style={{ backgroundColor: "#1f1f81", color: "whitesmoke" }} >Send Message</button>
            <div id="msgSubmit" className="h3 text-center hidden"></div>
            <div className="clearfix"></div>
          </Form>
        </Modal.Body>

      </Modal>
    </>
  )
}

export default Header