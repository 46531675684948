import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'


const Blogs = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Blogs</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className='container mb-4' id="events" data-aos="zoom-in" data-aos-duration="2000">
                <h1 style={{ textAlign: 'center', color: '#9932CC', marginTop: '30px' }}>
                    <span style={{ color: "#1f1f81" }}><b>Blogs</b></span>
                </h1>
            </div>
            <div className='container text-center mb-4' data-aos="zoom-in-right" data-aos-duration="2000" style={{ alignItems: 'center', paddingLeft: '60px', justifyContent: 'center' }}>
                <div className='row' style={{ justifyContent: 'center' }}>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="Popping_20Pinples_MobileHomeFeature.png" alt='' />
                            <Card.Body>
                                <Card.Title>ACNE AND PIMPLES PREVENTION TIPS, CAUSES & TREATMENTS</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Acne_And_Pimples_Prevention_Tips_Causes_And_Treatments' className='blogsButton' >Know More</Link>

                            </Card.Body>
                        </Card>

                    </div>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="MNRF-Treatment-1.jpg" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>MNRF TREATMENT</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/MNRF_Treatment_In_Hyderabad' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="winterSkinCare.webp" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>WINTER SKIN CARE</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/How_To_Take_Skin_Care_In_Winter' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>

            <div className='container text-center' data-aos="zoom-in-right" data-aos-duration="2000" style={{ alignItems: 'center', paddingLeft: '60px', justifyContent: 'center' }}>
                <div className='row' style={{ justifyContent: 'center' }}>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="HairLoss.jpg" alt='' />
                            <Card.Body>
                                <Card.Title>Hair Loss (Alopecia) in Men: Causes, Treatments, and Coping Strategies</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Hair_loss_alopecia_in_men' className='blogsButton' >Know More</Link>

                            </Card.Body>
                        </Card>

                    </div>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="monsoonHairCare.png" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Hair care Monsoon</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Hair_care_Monsoon' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="LaserTreatment.jpg" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Laser Treatment for Your Skin and Hair!</Card.Title>
                                <Card.Text>
                                </Card.Text>
                                <Link to='/Laser_treatment_options_your_skin_and_hair' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>

            <div className='container text-center' data-aos="zoom-in-right" data-aos-duration="2000" style={{ alignItems: 'center', paddingLeft: '60px', justifyContent: 'center' }}>
                <div className='row' style={{ justifyContent: 'center' }}>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="DrySkinTreatment.jpg" alt='' />
                            <Card.Body>
                                <Card.Title>9 Dry Skin Treatment and Prevention Tips from Dermatologist</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Dry_Skin_Treatment_and_Prevention_Tips_from_Dermatologist' className='blogsButton' >Know More</Link>

                            </Card.Body>
                        </Card>

                    </div>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="Cosmelan-3.jpg" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Cosmelan Peel Treatment: Benefits, Results, Recovery & Risks</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Cosmelan_Peel_Treatment' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="TopSkinCare.png" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Top 10 Skin Care Tips From Dr Sai Prasanth Neti</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Top_10_Skin_Care_Tips_From_Dr_Sai_Prasanth_Netis' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>

            <div className='container text-center mt-3' data-aos="zoom-in-right" data-aos-duration="2000" style={{ alignItems: 'center', paddingLeft: '60px', justifyContent: 'center' }}>
                <div className='row' style={{ justifyContent: 'center' }}>
                    <div className='col  mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="Gfc_Treatment_Benefits.png" alt='' style={{ height: '180px' }} />
                            <Card.Body>
                                <Card.Title>Benefits Of GFC Hair Treatment For Ultimate Hair Care</Card.Title>
                                <Card.Text>
                                </Card.Text>
                                <Link to='/Benefits_of_gfc_hair_treatment_for_ultimate_hair_care' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col  mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="PRP-for-face.webp" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>PRP Treatment</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/PRP_Treatment_In_Hyderabad' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col  mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="skin-pigmentation.png" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Skin Pigmentation</Card.Title>
                                <Card.Text>
                                </Card.Text>
                                <Link to='/Skin_Pigmentation' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>


                </div>
            </div>

            <div className='container text-center' data-aos="zoom-in-right" data-aos-duration="2000" style={{ alignItems: 'center', paddingLeft: '60px', justifyContent: 'center' }}>
                <div className='row' style={{ justifyContent: 'center' }}>
                    <div className='col  mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="HyperPigmentationImage.png" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>HyperPigmentation</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/HyperPigmentation' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col  mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="Skin_care.webp" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Summer Skin Care Tips</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/summer_skin_care_tips' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className='col  mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="Summer-Hair-Care.webp" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Top 10 Common Summer Skin And Hair Problems</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Top_10_Common_Summer_Skin_and_Hair_Problems_preventions_and_Solutions' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>



            <div className='container text-center' data-aos="zoom-in-right" data-aos-duration="2000" style={{ alignItems: 'center', paddingLeft: '60px', justifyContent: 'center' }}>
                <div className='row' style={{ justifyContent: 'center' }}>
                    <div className='col  mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="SummerCare.jpg" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Hair Care Tips for the Summer Season</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/haircare_tips_for_summer_season' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>

                    </div>
                    <div className='col  mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="mnrf Acne treatment.jpg" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>MNRF for Acne Scar Treatment in Hyderabad</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/MNRF_for_Acne_Scar_Treatment_in_Hyderabad' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className='col  mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="mnrf cost.jpeg" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>MNRF Treatment Cost in Hyderabad</Card.Title>
                                <Card.Text>
                                </Card.Text>
                                <Link to='/MNRF_Treatment_Cost_in_Hyderabad' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>


                </div>
            </div>


            <div className='container text-center' data-aos="zoom-in-right" data-aos-duration="2000" style={{ alignItems: 'center', paddingLeft: '60px', justifyContent: 'center' }}>
                <div className='row mt-4' style={{ justifyContent: 'center' }}>
                    <div className='col-md-4 col-sm-12  mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="Monsoon Hair and skin Care Tips.webp" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Monsoon Skin and Hair Care Tips</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Monsoon_Skin_and_Hair_Care_Tips' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>

                    </div>
                    <div className='col-md-4 col-sm-12  mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="hair care Tips.avif" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>10 best Monsoon Hair Care Tips</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/10_Best_Monsoon_Hair_Care_Tips' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className='col-md-4 col-sm-12  mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="hair grouth.png" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>8 Best Hair Growth Supplements That You’re Unaware Of</Card.Title>
                                <Card.Text>
                                </Card.Text>
                                <Link to='/8_Best_Hair_Growth_Supplements_That_You’re_Unaware_Of' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>


                </div>
            </div>

            <div className='container text-center' data-aos="zoom-in-right" data-aos-duration="2000" style={{ alignItems: 'center', paddingLeft: '60px', justifyContent: 'center' }}>
                <div className='row' style={{ justifyContent: 'center' }}>
                    <div className='col-md-4 col-sm-12  mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="stressHairloss.png" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>IS HAIR LOSS OR ALOPECIA STRESSING YOU OUT ?</Card.Title>
                                <Card.Text>
                                </Card.Text>
                                <Link to='/IS_HAIR_LOSS_OR_ALOPECIA_STRESSING_YOU_OUT' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>

                    </div>
                    <div className='col-md-4 col-sm-12  mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="laser-hair-removal-2x.png" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Laser Hair Removal Benefits and Side Effects</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Laser_Hair_Removal_Benefits_and_Side_Effects' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className='col-md-4 col-sm-12  mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="Laser-Toning1.png" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>8 key benifits of laser toning</Card.Title>
                                <Card.Text>
                                </Card.Text>
                                <Link to='/8_Key_Benifits_Of_Laser_Toning' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div> 
                    <div className='col-md-4 col-sm-12  mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="STRETCH MARKS REMOVAL.jpg" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Stretch Marks Causes Treatment Options and Prevention</Card.Title>
                                <Card.Text>
                                </Card.Text>
                                <Link to='/Stretch_Marks_Causes_Treatment_Options_and_Prevention' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col-md-4 col-sm-12  mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="Lip-Filler-101.jpg" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Lip Fillers 101: Everything You Need to Know for Perfect Lips</Card.Title>
                                <Card.Text>
                                </Card.Text>
                                <Link to='/Lip_Fillers_101_Everything_You_Need_to_Know_for_Perfect_Lips' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                    
                    <div className='col-md-4 col-sm-12  mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="Rejuvenation-Treatment-at-Arnica-Clinic-Pune.webp" alt='Best Skin Rejuvenation Treatments for a Youthful Glow - Netis Skin Hair and Nail Clinic' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Best Skin Rejuvenation Treatments for a Youthful Glow - Netis Skin Hair and Nail Clinic</Card.Title>
                                <Card.Text>
                                </Card.Text>
                                <Link to='/Best_Skin_Rejuvenation_Treatments_for_a_Youthful_Glow_Netis_Skin_Hair_and_Nail_Clinic' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default Blogs