import React from 'react'
import { Link } from 'react-router-dom'


const Monsoon_Skin_and_Hair_Care_Tips = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Monsoon Skin and Hair Care Tips</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-3 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <p>Monsoon season can bring a host of challenges for skin and hair due to increased humidity, dampness, and the potential for infections. Here are some effective tips to take care of your skin and hair during the monsoon:</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Skin Care Tips</span>
                        </h4 >
                        <ul>
                            <li><b>Cleansing: </b>Use a gentle cleanser to keep your skin clean and prevent breakouts. Look for products with natural ingredients that can combat excess oil without stripping your skin of its natural moisture.</li>
                            <li><b>Exfoliation: </b>Exfoliate your skin once or twice a week to remove dead skin cells and prevent clogged pores. Use a mild scrub to avoid irritation.</li>
                            <li><b>Moisturization: </b>Even in humid weather, your skin needs hydration. Use a lightweight, non-comedogenic moisturizer to keep your skin soft and supple.</li>
                            <li><b>Sunscreen: </b>Don’t skip sunscreen even if it’s cloudy. Use a broad-spectrum SPF to protect your skin from harmful UV rays.</li>
                            <li><b>Toning: </b>Use an alcohol-free toner to help maintain your skin’s pH balance and tighten pores.</li>
                            <li><b>Anti-Fungal Powder: </b>Apply anti-fungal powder to areas prone to sweating to prevent fungal infections.</li>
                            <li><b>Hydration: </b>Drink plenty of water to keep your skin hydrated from within.</li>
                            <li><b>Diet: </b>Include antioxidant-rich foods in your diet, like fruits and vegetables, to promote healthy skin.</li>
                        </ul>

                    </div>
                    <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='Monsoon Hair and skin Care Tips.webp' alt=''></img>
                    </div>
                </div>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Hair Care Tips</span>
                </h4 >
                <ul>
                    <li><b>Regular Washing: </b>Wash your hair regularly to remove excess oil and dirt. Use a mild, sulfate-free shampoo to avoid stripping your hair of natural oils.</li>
                    <li><b>Conditioning: </b>Use a lightweight conditioner to keep your hair manageable and prevent frizz. Focus on the ends of your hair to avoid weighing down the roots.</li>
                    <li><b>Drying: </b>Avoid tying up wet hair as it can lead to fungal infections. Let your hair dry naturally or use a blow dryer on a cool setting.</li>
                    <li><b>Anti-Frizz Serum: </b>Apply an anti-frizz serum or leave-in conditioner to control frizz and add shine to your hair.</li>
                    <li><b>Oil Massage: </b>Give yourself a regular scalp massage with a light oil like coconut or almond oil to nourish your scalp and hair.</li>
                    <li><b>Avoid Heat Styling: </b>Minimize the use of heat styling tools as they can cause further damage and dryness.</li>
                    <li><b>Hair Masks: </b>Use a deep conditioning hair mask once a week to nourish and strengthen your hair.</li>
                    <li><b>Diet: </b>Eat a balanced diet rich in vitamins and minerals to promote healthy hair growth.</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>General Tips</span>
                </h4 >
                <ul>
                    <li><b>Avoid Heavy Makeup: </b>Heavy makeup can clog pores in humid weather. Opt for lightweight, non-comedogenic products.</li>
                    <li><b>Wear Breathable Fabrics: </b>Choose cotton or other breathable fabrics to allow your skin to breathe and prevent sweat buildup.</li>
                    <li><b>Foot Care: </b>Keep your feet clean and dry. Use anti-fungal powder and wear open-toed footwear when possible.</li>
                    <li><b>Stay Hygienic: </b>Take regular showers and keep your body clean to prevent infections and maintain overall hygiene.</li>
                </ul>

                <p>Dr Sai Prasanth Neti <a href='https://drnetisclinic.com/'>best Cosmetologist doctor near me</a></p>

            </div>

        </>
    )
}

export default Monsoon_Skin_and_Hair_Care_Tips