import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Testmonialss from './Testmonialss';


import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';



const Home = () => {
    return (
        <>
            <div style={{ paddingTop: '130px' }}>
                <Carousel className=" homeclass" variant='dark' indicators={false}  >
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="web_1.png"
                            alt="First slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="web 2.png"
                            alt="Second slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="web 3.png"
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="web 4.png"
                            alt="fourth slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="web 5.png"
                            alt="fifth slide"
                        />

                    </Carousel.Item>
                </Carousel>
                {/* <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active" data-bs-interval="100">
                <img src="web_2_3.png" class="d-block w-100" alt="..."/>
              </div>
              <div class="carousel-item" data-bs-interval="2000">
                <img src="web_2_3.png" class="d-block w-100" alt="..."/>
              </div>
              <div class="carousel-item">
                <img src="web_2_3.png" class="d-block w-100" alt="..."/>
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
              <span class="carousel-control-prev-<b>icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previ<b>ous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
              <span class="carousel-control-next-<b>icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next<<b>/span>
            </button>
          </div> */}

            </div>

            <div data-aos="flip-left" data-aos-duration="2000" className="container mt-5 mb-2 PARASET" >
                <div className="row">
                    {/* <div class="col-lg-4 mb-5" style={{ textAlign: 'center', paddingTop: '80px' }}>
                        <img className="img-thumbnail shadow-lg p-1 mb-5 bg-body rounded" style={{ width: "310px", height: '350px' }} src='hospital.png' alt=''></img>
                    </div> */}

                    <div className="col mb-2">
                        <h1 style={{ textAlign: 'center', color: '#1f1f81', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}><b>Dr Neti's Skin Hair And Nail Clinic</b></span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            At DR.NETI'S Skin Hair and Nail Clinic, we are dedicated to enhancing your natural beauty and well-being. Driven by a passion for excellence, our clinic is led by Dr. Sai Prasanth Neti, a highly trained Cosmetologist and Dermatologist. With his extensive knowledge and experience, Dr. Neti is committed to providing the highest quality care to help you look and feel your best.
                        </p>
                        <p style={{ textAlign: 'justify' }}>
                            Dr. Sai Prasanth Neti is a distinguished member of the Indian Association of Dermatologists, Venereologists, and Leprologists (IADVL). He holds an M.D. degree , demonstrating his dedication to continuous learning and staying at the forefront of dermatological and cosmetological advancements.
                        </p>



                    </div>
                </div>
            </div>
            {/* heading..... */}
            <div>
                <h1 style={{ textAlign: 'center', color: '#1f1f81', marginTop: '30px' }}>
                    <span style={{ color: "#1f1f81" }}><b>Centres Of Excellence</b></span>
                </h1>
            </div>



            {/* cards........ */}

            <div className="container text-center facility cards mb-3">
                <div className="row facilityRow" style={{ textAlign: 'center', justifyContent: 'center' }}>
                    <div className="col-lg-3 mb-4 facilityColum" style={{ width: '280px', display: 'flex', height: '450px' }}>
                        <div className="card text-center pb-4 shadow mb-2" style={{ color: '#1f1f81', height: '470px' }}>
                            <div className="card-body">
                                <img src="Acne care.png" alt='' style={{ width: '220px', height: '180px', backgroundColor: 'white', imageOrientation: 'initial', marginBottom: '2PX', borderRadius: '5px' }}></img>
                                <h5 className="card-title">Acne & Acne Scars Treatment</h5>
                                <p className="lead" style={{ color: 'black', fontSize: 'medium' }}>Acne, also known as acne vulgaris, is a long-term skin condition that occurs when dead skin cells and oil from the skin clog hair follicles.</p>
                                <Link to='/Acne_And_Acne_Scars_Treatment'><Button style={{ backgroundColor: '#fecb3e' }}>Know More</Button></Link>
                            </div>
                        </div>
                    </div>

                    {/* card 2 */}
                    <div className="col-lg-3 mb-5 facilityColum  " style={{ width: '280px', display: 'flex', height: '440px' }}>
                        <div className="card text-center pb-4 shadow mb-2" style={{ color: '#1f1f81', height: '470px' }}>
                            <div className="card-body">
                                <img src="MNRF-Treatment-1.jpg" alt='' style={{ width: '220px', height: '180px', backgroundColor: 'white', imageOrientation: 'initial', marginBottom: '2PX', borderRadius: '5px' }}></img>
                                <h5 className="card-title" >MNRF Treatment</h5>
                                <p className="lead" style={{ color: 'black', fontSize: 'medium' }}>Microneedling is mainly used on the face and reduces the appearance of acne, scars, dark spots, wrinkles, and large pores. This treatment works best to reduce cellulite.</p>
                                <Link to='/MNRF_Treatment'><Button style={{ backgroundColor: '#fecb3e' }} >Know More</Button></Link>
                            </div>
                        </div>
                    </div>

                    {/* card 3 */}
                    <div className="col-lg-3 mb-5 facilityColum" style={{ width: '280px', display: 'flex', height: '430px' }}>
                        <div className="card text-center pb-4 shadow mb-2" style={{ color: '#1f1f81', height: '470px' }}>
                            <div className="card-body">
                                <img src="strechmarksbeforeafter.png" alt='' style={{ width: '220px', height: '180px', backgroundColor: 'white', imageOrientation: 'initial', marginBottom: '2PX', borderRadius: '5px' }}></img>
                                <h5 className="card-title">Stretch Marks</h5>
                                <p className="lead" style={{ color: 'black', fontSize: 'medium' }}>stretch marks is stretching of the skin. Their severity is affected by several factors, including your genetics and the degree of stress on the skin. Your level of the hormone cortisol also might play a role</p>
                                <Link to='/Stretch_Marks_Treatment'><Button style={{ backgroundColor: '#fecb3e', marginTop: '-10PX' }}>Know More</Button></Link>
                            </div>
                        </div>
                    </div>

                    {/* card 4 */}
                    <div className="col-lg-3 mb-5 facilityColum" style={{ width: '280px', display: 'flex', height: '430px' }}>
                        <div className="card text-center pb-4 shadow mb-2" style={{ color: '#1f1f81', height: '470px' }}>
                            <div className="card-body">
                                <img src="PRP-for-Face-and-Hair.jpg" alt='' style={{ width: '220px', height: '180px', backgroundColor: 'white', imageOrientation: 'initial', marginBottom: '2PX', borderRadius: '5px' }}></img>
                                <h5 className="card-title">PRP For Face And Hair</h5>
                                <p className="lead" style={{ color: 'black', fontSize: 'medium' }}> promote blood circulation to the scalp and nourish hair follicles. This process can stimulate the dormant (inactive or sleeping) hair follicles, extend the growth phase of existing hair, and enhance hair thickness.</p>
                                <Link to='/Prp_For_Face_And_Hair'><Button style={{ backgroundColor: '#fecb3e', marginTop: '-10PX' }} >Know More</Button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="container text-center facility cards mb-5" data-aos="zoom-in-right" data-aos-duration="2000" >
                <div className="row facilityRow" style={{ textAlign: 'center', justifyContent: 'center' }}>
                    <div className="col-lg-3 mb-5  facilityColum mt-5" style={{ width: '280px', display: 'flex', height: '430px', alignItems: 'center' }}>

                        {/* card 5 */}
                        <div className="card text-center pb-4 shadow  mb-2" style={{ color: '#1f1f81', height: '470px' }}>
                            <div className="card-body">
                                <img src="NAIL SURGERY1.jpg" alt='' style={{ width: '220px', height: '180px', backgroundColor: 'white', imageOrientation: 'initial', marginBottom: '2PX', borderRadius: '5px' }}></img>
                                <h5 className="card-title">Nail Surgery</h5>
                                <p className="lead" style={{ color: 'black', fontSize: 'medium' }}>Nail surgery is a minor surgical procedure to remove all or part of a nail under local analgesia. Following the removal, the nail bed is treated with a chemical to stop the nail from growing back again. </p>
                                <Link to='/Nail_Surgery'><Button style={{ backgroundColor: '#fecb3e', marginTop: '-10px' }} >Know More</Button></Link>
                            </div>
                        </div>
                    </div>

                    {/* card 6 */}
                    <div className="col-lg-3 mb-5  facilityColum mt-3 " style={{ width: '280px', display: 'flex', height: '430px', marginBottom: '80px' }}>
                        <div className="card text-center pb-4 shadow mb-2" style={{ color: "#1f1f81", height: '470px' }}>
                            <div className="card-body">
                                <img src="Growth factor therapy.jpg" alt='' style={{ width: '220px', height: '180px', backgroundColor: 'white', imageOrientation: 'initial', marginBottom: '2PX', borderRadius: '5px' }}></img>
                                <h5 className="card-title">Growth Factor Concentrate Therapy</h5>
                                <p className="lead" style={{ color: 'black', fontSize: 'medium' }}>Growth factor therapy is the use of growth factors to promote wound healing. Growth factors are substances secreted by the body whose function is to stimulate the growth.</p>
                                <Link to='/Growth_factor_concentrate_therapy'><Button style={{ backgroundColor: '#fecb3e', marginTop: '-10px' }} >Know More</Button></Link>
                            </div>
                        </div>
                    </div>

                    {/* card 7 */}
                    <div className="col-lg-3 mb-5 facilityColum mt-3 " style={{ width: '280px', display: 'flex', height: '430px', }}>
                        <div className="card text-center pb-4 shadow mb-2" style={{ color: "#1f1f81", height: '470px' }}>
                            <div className="card-body">
                                <img src="Wrinkles Treatment.jpeg" alt='' style={{ width: '220px', height: '180px', backgroundColor: 'white', imageOrientation: 'initial', marginBottom: '2PX', borderRadius: '5px' }}></img>
                                <h5 className="card-title">Wrinkles Treatment</h5>
                                <p className="lead" style={{ color: 'black', fontSize: 'medium' }}>Wrinkles are the lines that develop on your skin as you get older. Whether it’s frown lines or crow’s feet, getting wrinkles is normal and a natural part of ageing. </p>
                                <Link to='/Wrinkles_Treatment'><Button style={{ backgroundColor: '#fecb3e' }} >Know More</Button></Link>
                            </div>
                        </div>
                    </div>

                    {/* card 8 */}
                    <div className="col-lg-3 mb-5 facilityColum mt-3" style={{ width: '280px', display: 'flex', height: '430px' }}>
                        <div className="card  text-center pb-2 shadow mb-2" style={{ color: "#1f1f81", height: '470px' }}>
                            <div className="card-body">
                                <img src="Anti-Aging Treatment.jpg" alt='' style={{ width: '220px', height: '180px', backgroundColor: 'white', imageOrientation: 'initial', marginBottom: '2PX', borderRadius: '5px' }}></img>
                                <h5 className="card-title">Anti-Aging Treatment</h5>
                                <p className="lead" style={{ color: 'black', fontSize: 'medium' }}>Anti-aging treatment encompasses various practices and interventions aimed at slowing, preventing, or reversing the aging process in humans. </p>
                                <Link to='/Antiaging_Treatments'><Button style={{ backgroundColor: '#fecb3e' }} >Know More</Button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* get to know our team of experience..... */}



            <div className='container mt-5' >
                <h1 style={{ textAlign: 'center', color: '#9932CC', fmarginTop: '30px' }}>
                    <span style={{ color: "#1f1f81" }}><b>About Dr.Sai Prasanth Neti</b></span>
                </h1>
            </div>

            <div className="container mb-2 " >

                <div className="row">
                    <div className="col-lg-7 mb-2">

                        <p>
                            <strong>
                                <br />
                                <br />
                                Dr.Sai Prasanth Neti<br />
                            </strong>
                        </p>

                        <p style={{ textAlign: 'justify' }}>
                            Dr. Sai Prasanth Neti is a distinguished member of the Indian Association of Dermatologists, Venereologists, and Leprologists (IADVL). He holds an M.D. degree , demonstrating his dedication to continuous learning and staying at the forefront of dermatological and cosmetological advancements.
                        </p>
                        <p style={{ textAlign: 'justify' }}>
                            Dr. Sai Prasanth Neti is a highly regarded dermatologist, boasting an impressive academic background. and is also an esteemed member of the Federation of Aesthetic Dermatology and Laser Surgeons (FADS). Dr. Neti's unwavering passion for dermatology, cosmetology, and laser surgery underscores his commitment to delivering exceptional care.
                            Our Services
                        </p>
                        <p style={{ textAlign: 'justify' }}>
                            At DR.NETI'S Skin Hair and Nail Clinic, we offer a wide range of services in the fields of Cosmetology and Trichology, designed to address your unique skin, hair, and nail concerns.
                        </p>
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5  p-5">
                        <img style={{ width: "70%", height: '90%', boxShadow: '10px 10px grey' }} src='DrNetisPic.png' alt=''></img>
                    </div>
                </div>
            </div>

            {/* another side image cards......get to know our team of experience */}

            {/* <div className='container' data-aos="fade-up-right" data-aos-duration="3000" >
                <div className='row'>
                    <div className='col-md-6 '  >
                        <div className="card mb-3 " style={{ border: '4px solid #fff', boxShadow: '0 0 20px #ccc', borderRadius: '10px' }}>
                            <div className="row g-0">
                                <div className="col-md-4">
                                    <img src="Untitled-1gdfgdfgdf.png" className="img-fluid rounded-start" alt="..." />
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <h5 className="card-title">Title</h5>
                                        <p className="card-text">
                                        </p>
                                        <Link to='/Dr_Bharathi'><button className='btn ' style={{backgroundColor:'#fecb3e'}}>Read More</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='col-md-6'>
                        <div className="card mb-3" style={{ border: '4px solid #fff', boxShadow: '0 0 20px #ccc', borderRadius: '10px' }}  >
                            <div className="row g-0">
                                <div className="col-md-4">
                                    <img src="drfhgdfhdhdf.png" className="img-fluid rounded-start" alt="..." />
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <h5 className="card-title">Title</h5>
                                        <p className="card-text"></p>
                                            <Link to='/Dr_Kalyan_Chakravarthy_Koganti'><button className='btn ' style={{backgroundColor:'#fecb3e'}}>Read More</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Graduation from Guntur Medical College and Post Graduation from Manipal Academy of Higher Education Awarded PG certificate in Infectious diseases from London School of Hygiene and Tropical Medicine, UK. 
             Dr. Nelluri Bharathi, qualified with a Bachelor in Medicine and a Bachelor of Surgery (MBBS) and completed her post Graduation at Manipal Academy of Higher Education. Dr.Nelluri Bharath holds experience of 13 years. */}
            {/* <div className='container' data-aos="fade-right" data-aos-duration="3000">
                


            </div> */}
            {/* <div className='container' data-aos="fade-left" data-aos-duration="3000">
                <div className='row'>
                    <div className='col'  >
                        <div class="card mb-3" >
                            <div class="row g-0">
                                <div class="col-md-4">
                                    <img src="..." class="img-fluid rounded-start" alt="..." />
                                </div>
                                <div class="col-md-8">
                                    <div class="card-body">
                                        <h5 class="card-title">Card title</h5>
                                        <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='col' >
                        <div class="card mb-3" >
                            <div class="row g-0">
                                <div class="col-md-4">
                                    <img src="..." class="img-fluid rounded-start" alt="..." />
                                </div>
                                <div class="col-md-8">
                                    <div class="card-body">
                                        <h5 class="card-title">Card title</h5>
                                        <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </div>   */}


            {/* testmonials...... */}

            <div className='container' data-aos="zoom-in" data-aos-duration="3000">
                <div style={{ marginLeft: 'auto', marginRight: 'auto', height: '120px', width: '230px' }}>
                    <img style={{ height: '120px', width: '230px' }} src='Google-Reviews-transparent-2-300x150.png' alt='Google-Reviews' ></img>

                </div>
                <p style={{ textAlign: 'center' }}>Our team of experienced and accomplished doctors always give their best in providing ethical, modern and evidence based patient-centric treatment and always ensure health and safety of our patients.</p>
            </div>
            <div data-aos="zoom-in-up" data-aos-duration="3000" className='testmonials'>
                <Testmonialss />
            </div>



            {/* Events and news..... */}
            <div className='container mb-4' id="events" data-aos="zoom-in" data-aos-duration="2000">
                <h1 style={{ textAlign: 'center', color: '#9932CC', marginTop: '30px' }}>
                    <span style={{ color: "#1f1f81" }}><b>Blogs</b></span>
                </h1>
            </div>
            {/* <div className='container text-center' data-aos="zoom-in-right" data-aos-duration="2000" style={{ alignItems: 'center', paddingLeft: '60px', justifyContent: 'center' }}>
                <div className='row' style={{ justifyContent: 'center' }}>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="Popping_20Pinples_MobileHomeFeature.png" alt='' />
                            <Card.Body>
                                <Card.Title>ACNE AND PIMPLES PREVENTION TIPS, CAUSES & TREATMENTS</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Acne_And_Pimples_Prevention_Tips_Causes_And_Treatments' className='blogsButton' >Know More</Link>

                            </Card.Body>
                        </Card>

                    </div>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="MNRF-Treatment-1.jpg" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>MNRF TREATMENT</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/MNRF_Treatment_In_Hyderabad' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col mb-2'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="winterSkinCare.webp" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>WINTER SKIN CARE</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/How_To_Take_Skin_Care_In_Winter' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div> */}

            <div className='container swip mt-5 text-center' style={{ marginBottom: '120px' }}>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar]}
                    spaceBetween={50}
                    slidesPerView={4}

                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,

                            spaceBetween: 20,
                        },
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                        }
                    }}
                >
                     <SwiperSlide className='mb-5'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="Rejuvenation-Treatment-at-Arnica-Clinic-Pune.webp" alt='Best Skin Rejuvenation Treatments for a Youthful Glow - Netis Skin Hair and Nail Clinic' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Best Skin Rejuvenation Treatments for a Youthful Glow - Netis Skin Hair and Nail Clinic</Card.Title>
                                <Card.Text>
                                </Card.Text>
                                <Link to='/Best_Skin_Rejuvenation_Treatments_for_a_Youthful_Glow_Netis_Skin_Hair_and_Nail_Clinic' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                     </SwiperSlide>
                    
                    <SwiperSlide className='mb-5'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="Lip-Filler-101.jpg" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Lip Fillers 101: Everything You Need to Know for Perfect Lips</Card.Title>
                                <Card.Text>
                                </Card.Text>
                                <Link to='/Lip_Fillers_101_Everything_You_Need_to_Know_for_Perfect_Lips' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className='mb-5'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="STRETCH MARKS REMOVAL.jpg" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Stretch Marks Causes Treatment Options and Prevention</Card.Title>
                                <Card.Text>
                                </Card.Text>
                                <Link to='/Stretch_Marks_Causes_Treatment_Options_and_Prevention' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>
                   
                    <SwiperSlide className='mb-5'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="Laser-Toning1.png" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>8 key benifits of laser toning</Card.Title>
                                <Card.Text>
                                </Card.Text>
                                <Link to='/8_Key_Benifits_Of_Laser_Toning' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>

                    </SwiperSlide>
                    <SwiperSlide className='mb-5'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="STRETCH MARKS REMOVAL.jpg" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Stretch Marks Causes Treatment Options and Prevention</Card.Title>
                                <Card.Text>
                                </Card.Text>
                                <Link to='/Stretch_Marks_Causes_Treatment_Options_and_Prevention' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className='mb-5'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="laser-hair-removal-2x.png" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Laser Hair Removal Benefits and Side Effects</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Laser_Hair_Removal_Benefits_and_Side_Effects' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className='mb-5'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="stressHairloss.png" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>IS HAIR LOSS OR ALOPECIA STRESSING YOU OUT ?</Card.Title>
                                <Card.Text>
                                </Card.Text>
                                <Link to='/IS_HAIR_LOSS_OR_ALOPECIA_STRESSING_YOU_OUT' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className='mb-5'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="hair grouth.png" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>8 Best Hair Growth Supplements That You’re Unaware Of</Card.Title>
                                <Card.Text>
                                </Card.Text>
                                <Link to='/8_Best_Hair_Growth_Supplements_That_You’re_Unaware_Of' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className='mb-5'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="hair care Tips.avif" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>10 best Monsoon Hair Care Tips</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/10_Best_Monsoon_Hair_Care_Tips' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className='mb-5'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="Monsoon Hair and skin Care Tips.webp" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Monsoon Skin and Hair Care Tips</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Monsoon_Skin_and_Hair_Care_Tips' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className='mb-5'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="mnrf cost.jpeg" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>MNRF Treatment Cost in Hyderabad</Card.Title>
                                <Card.Text>
                                </Card.Text>
                                <Link to='/MNRF_Treatment_Cost_in_Hyderabad' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className='mb-5'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="mnrf Acne treatment.jpg" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>MNRF for Acne Scar Treatment in Hyderabad</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/MNRF_for_Acne_Scar_Treatment_in_Hyderabad' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className='mb-5'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="SummerCare.jpg" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Hair Care Tips for the Summer Season</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/haircare_tips_for_summer_season' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className='mb-5'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="Summer-Hair-Care.webp" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Top 10 Common Summer Skin And Hair Problems</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Top_10_Common_Summer_Skin_and_Hair_Problems_preventions_and_Solutions' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className='mb-5'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="Skin_care.webp" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Summer Skin Care Tips</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/summer_skin_care_tips' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>

                    <SwiperSlide className='mb-5'>

                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="Gfc_Treatment_Benefits.png" alt='' style={{ height: '180px' }} />
                            <Card.Body>
                                <Card.Title>Benefits Of GFC Hair Treatment For Ultimate Hair Care</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Benefits_of_gfc_hair_treatment_for_ultimate_hair_care' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>

                    <SwiperSlide className='mb-5'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="PRP-for-face.webp" alt='' />
                            <Card.Body>
                                <Card.Title>PRP Treatment</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/PRP_Treatment_In_Hyderabad' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>

                    <SwiperSlide className='mb-5'>
                        <Card className='card' style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="Popping_20Pinples_MobileHomeFeature.png" alt='' />
                            <Card.Body>
                                <Card.Title>ACNE AND PIMPLES PREVENTION TIPS, CAUSES & TREATMENTS</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Acne_And_Pimples_Prevention_Tips_Causes_And_Treatments' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className='mb-5'>
                        <Card className='card' style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="MNRF-Treatment-1.jpg" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>MNRF TREATMENT</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/MNRF_Treatment_In_Hyderabad' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className='mb-5'>
                        <Card className='card' style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="winterSkinCare.webp" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>WINTER SKIN CARE</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/How_To_Take_Skin_Care_In_Winter' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className='mb-5'>
                        <Card className='card' style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="HairLoss.jpg" alt='' />
                            <Card.Body>
                                <Card.Title>Hair Loss (Alopecia) in Men: Causes, Treatments, and Coping Strategies</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Hair_loss_alopecia_in_men' className='blogsButton' >Know More</Link>

                            </Card.Body>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className='mb-5'>
                        <Card className='card' style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="monsoonHairCare.png" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Hair care Monsoon</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Hair_care_Monsoon' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className='mb-5'>
                        <Card className='card' style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="LaserTreatment.jpg" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Laser Treatment for Your Skin and Hair!</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Laser_treatment_options_your_skin_and_hair' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className='mb-5'>
                        <Card className='card' style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="DrySkinTreatment.jpg" alt='' />
                            <Card.Body>
                                <Card.Title>9 Dry Skin Treatment and Prevention Tips from Dermatologist</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Dry_Skin_Treatment_and_Prevention_Tips_from_Dermatologist' className='blogsButton' >Know More</Link>

                            </Card.Body>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className='mb-5'>
                        <Card className='card' style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="Cosmelan-3.jpg" alt='' />
                            <Card.Body>
                                <Card.Title>Cosmelan Peel Treatment: Benefits, Results, Recovery & Risks</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Cosmelan_Peel_Treatment' className='blogsButton' >Know More</Link>

                            </Card.Body>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className='mb-5'>
                        <Card className='card' style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="TopSkinCare.png" alt='' />
                            <Card.Body>
                                <Card.Title>Top 10 Skin Care Tips From Dr Sai Prasanth Neti</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Top_10_Skin_Care_Tips_From_Dr_Sai_Prasanth_Netis' className='blogsButton' >Know More</Link>

                            </Card.Body>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide className='mb-5'>
                        <Card className='card' style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="skin-pigmentation.png" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>Skin Pigmentation</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/Skin_Pigmentation' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>

                    </SwiperSlide>
                    <SwiperSlide className='mb-5'>
                        <Card className='card' style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="HyperPigmentationImage.png" alt='' style={{ height: '200px' }} />
                            <Card.Body>
                                <Card.Title>HyperPigmentation</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                <Link to='/HyperPigmentation' className='blogsButton' >Know More</Link>
                            </Card.Body>
                        </Card>
                    </SwiperSlide>




                </Swiper>
            </div>

            {/* Insurance.... */}

            {/* <div class="right-navigation py-5">
                <button class="r-n-title1" onClick={()=>setSlide(true)} >Insurance</button>
              { slide && <div class="sidenav" id="mySidenav" style={{ minHeight: '260px',backgroundColor: 'white', transition: 'all ease .3s',top:'0' ,padding: '5px',textAlign: 'center',borderRadius:'5px'}}>
                    <div class="loactios-close" style={{position:"initial"}} onClick={()=>setSlide(false)}>X</div>
                    <div class="location-info">
                        <div class="location-title" style={{marginTop:'-25px'}}>
                            Available Insurances
                        </div>
                        <div class="row py-3">
                            <div class="col-md-3 col-3">
                                <img src="ABH.png" alt="image" />
                            </div>
                            <div class="col-md-3 col-3">
                                <img src="FGI.png" alt="image" />
                            </div>

                            <div class="col-md-3 col-3">
                                <img src="FHPL.png" alt="image" />
                            </div>

                            <div class="col-md-3 col-3">
                                <img src="GD.png" alt="image" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-3 col-3">
                                <img src="GH.png" alt="image" />
                            </div>
                            <div class="col-md-3 col-3">
                                <img src="HI.png" alt="image" />
                            </div>

                            <div class="col-md-3 col-3">
                                <img src="ICICI.png" alt="image" />
                            </div>

                            <div class="col-md-3 col-3">
                                <img src="IFFCO.png" alt="image" />
                            </div>
                        </div>
                    </div>
                    <div class="location-info">
                        <div class="row">
                            <div class="col-md-4 col-4">
                                <img src="MA.png" alt="image" />
                            </div>
                            <div class="col-md-4 col-4">
                                <img style={{height:'50px',width:'100px'}}  src="MC.png" alt="image" />
                            </div>

                            {/* <div class="col-md-3 col-3">
                                <img src="MDI.png" alt="image" />
                            </div> */}

            {/* <div class="col-md-4 col-4">
                                <img style={{height:'70px',width:'70px'}}  src="MHI.png" alt="image" />
                            </div>
                        </div>
                    </div>
                    <div class="location-info">
                        <div class="row">
                            <div class="col-md-3 col-3">
                                <img style={{height:'50px',width:'50px'}} src="NABH.png" alt="image" />
                            </div>
                            <div class="col-md-3 col-3">
                                <img src="RGI.png" alt="image" />
                            </div>

                            <div class="col-md-3 col-3">
                                <img src="RHI.png" alt="image" />
                            </div>

                            <div class="col-md-3 col-3">
                                <img src="STAR.png" alt="image" />
                            </div>
                        </div>
                    </div>
                    <div class="location-info">
                        <div class="row">
                            <div class="col-md-4 col-4">
                                <img style={{height:'50px',width:'110px'}}  src="SW.png" alt="image" />
                            </div>
                            <div class="col-md-4 col-4">
                                <img src="UHC.png" alt="image" />
                            </div>

                            <div class="col-md-4 col-4">
                                <img src="VH.png" alt="image" />
                            </div>

                           
                        </div>
                    </div>
                </div>}
            </div> */}
        </>
    )
}

export default Home