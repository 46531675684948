import React from 'react'
import { Link } from 'react-router-dom'


const Acne_And_Pimples_Prevention_Tips_Causes_And_Treatments = () => {
  return (
    <>
      <div style={{ height: '120px' }}></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-5" >
            <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Acne And Pimples Prevention Tips, Causes & Treatments</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
            <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
          </ul>
        </div>
      </div>

      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#1f1f81" }}>Acne And Pimples Prevention Tips, Causes & Treatments</span>
            </h1>
            <p style={{ textAlign: 'justify' }}>
              Acne and pimples, though common, can be frustrating and affect individuals of all ages. Understanding the causes and implementing effective prevention strategies is key to achieving clear and healthy skin. In this blog post, we'll explore the origins of acne, provide practical prevention tips, and discuss various treatment options to help you on your journey to blemish-free skin.                        </p>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#fecb3e" }}>Understanding Acne: Causes and Types</span>
            </h4 >

            <h6 style={{ fontSize: '26px !important', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#1f1f81" }}>Excess Sebum Production</span>
            </h6 >
            <p style={{ textAlign: 'justify' }}>Overactive oil glands can lead to the accumulation of sebum (skin oil) on the skin's surface, clogging pores and contributing to acne development</p>


          

          </div>
          <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
            <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='Popping_20Pinples_MobileHomeFeature.png' alt=''></img>
          </div>
        </div>
        <h6 style={{ fontSize: '26px !important', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#1f1f81" }}>Bacterial Infection</span>
            </h6 >
            <p style={{ textAlign: 'justify' }}>Propionibacterium acnes, a type of bacteria, can proliferate in clogged pores and lead to inflammation, redness, and pus formation.</p>

            <h6 style={{ fontSize: '26px !important', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#1f1f81" }}>Hormonal Fluctuations</span>
            </h6 >
            <p style={{ textAlign: 'justify' }}>Changes in hormone levels, such as those that occur during puberty, menstruation, pregnancy, or due to hormonal disorders, can trigger acne outbreaks</p>

            <h6 style={{ fontSize: '26px !important', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#1f1f81" }}> Diet and Lifestyle</span>
            </h6 >
            <p style={{ textAlign: 'justify' }}>High-glycemic foods, dairy products, and excessive consumption of sugary or greasy foods can exacerbate acne. Stress, lack of sleep, and improper skincare can also contribute.</p>

            <h6 style={{ fontSize: '26px !important', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#1f1f81" }}> Genetics</span>
            </h6 >
            <p style={{ textAlign: 'justify' }}>A family history of acne can increase the likelihood of developing the condition.</p>



            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#fecb3e" }}>Prevention Tips for Acne and Pimples</span>
            </h4 >
            <h6 style={{ fontSize: '26px !important', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#1f1f81" }}> Cleanse Regularly but Gently</span>
            </h6 >
            <p style={{ textAlign: 'justify' }}>Use a mild, non-comedogenic cleanser to remove excess oil and dirt without over-drying the skin. Avoid harsh scrubs that can irritate the skin.</p>

            <h6 style={{ fontSize: '26px !important', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#1f1f81" }}>Stay Hydrated and Maintain a Balanced Diet</span>
            </h6 >
            <p style={{ textAlign: 'justify' }}>Drink plenty of water and incorporate fruits, vegetables, whole grains, and lean proteins into your diet. Limit high-glycemic foods, dairy, and excessive sugar or greasy items.</p>


            <h6 style={{ fontSize: '26px !important', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#1f1f81" }}>Avoid Touching Your Face</span>
            </h6 >
            <p style={{ textAlign: 'justify' }}>Touching your face can transfer dirt and bacteria, potentially exacerbating acne. Resist the urge to pop pimples, as it can lead to scarring and further inflammation.</p>


            <h6 style={{ fontSize: '26px !important', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#1f1f81" }}>Manage Stress</span>
            </h6 >
            <p style={{ textAlign: 'justify' }}>Practice stress-reducing techniques like meditation, yoga, or deep breathing exercises to help keep hormone levels in check.</p>


            <h6 style={{ fontSize: '26px !important', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#1f1f81" }}>Choose Non-Comedogenic Products</span>
            </h6 >
            <p style={{ textAlign: 'justify' }}>Use non-comedogenic skincare and makeup products that won't clog pores.</p>



            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#fecb3e" }}>Treatment Options for Acne and Pimples</span>
            </h4 >
            <h6 style={{ fontSize: '26px !important', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#1f1f81" }}>Topical Treatments</span>
            </h6 >
            <p style={{ textAlign: 'justify' }}>Over-the-counter or prescription topical treatments containing ingredients like benzoyl peroxide, salicylic acid, or retinoids can help unclog pores and reduce inflammation.</p>

            <h6 style={{ fontSize: '26px !important', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#1f1f81" }}>Oral Medications</span>
            </h6 >
            <p style={{ textAlign: 'justify' }}>In severe cases, a healthcare provider may prescribe oral antibiotics, hormonal therapies, or isotretinoin to address underlying causes.</p>

            <h6 style={{ fontSize: '26px !important', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#1f1f81" }}>Professional Procedures</span>
            </h6 >
            <p style={{ textAlign: 'justify' }}>Dermatological procedures like chemical peels, microdermabrasion, or laser therapy can be effective for stubborn or severe cases of acne</p>
            <p>Dr Sai Prasanth Neti <a href='https://drnetisclinic.com/'>best Dermatologist</a></p>

      </div>
    </>
  )
}

export default Acne_And_Pimples_Prevention_Tips_Causes_And_Treatments