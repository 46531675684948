import React from 'react'
import { Link } from 'react-router-dom'

const Dry_Skin_Treatment_and_Prevention_Tips_from_Dermatologist = () => {
  return (
    <>
     <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>9 Dry Skin Treatment and Prevention Tips from Dermatologist</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>9 Dry Skin Treatment and Prevention Tips from Dermatologist</span>
                        </h1>
                       <p>Washing, Scrubbing, Massaging and Moisturizing are the basic steps that will heal your dry skin. Though these strategies may not work round the year when the humidity is at its peak. Without a change in your skin care methods, dry air can bring fine lines and wrinkles. Moreover, excessive dryness can itch, flake, crack and may even bleed.</p>
                       <p>To help treat dry skin and prevent its return, dermatologists recommend the following:</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>1. Prevent long baths and showers from making dry skin worse. When your skin is dry, be sure to:</span>
                        </h4 >
                       <ul>
                        <li>Close the bathroom door</li>
                        <li>Limit your time in the shower or bath to 5 or 10 minutes</li>
                        <li>Wash with a gentle, fragrance-free cleanser</li>
                        <li>Apply enough cleanser to remove dirt and oil, but avoid using so much that you see a thick lather</li>
                        <li>Blot your skin gently dry with a towel</li>
                       </ul>
                      
                    </div>
                    <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='DrySkinTreatment.jpg' alt=''></img>
                    </div>
                </div>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>2. Apply moisturizer immediately after washing.</span>
                </h4>
                <p>Ointments, creams, and lotions (moisturizers) work by trapping existing moisture in your skin. To trap this much-needed moisture, you need to apply a moisturizer within few minutes of:</p>
                <ul>
                  <li>Drying off after a shower or bath</li>
                  <li>Washing your face or hands</li>
                </ul>
              
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Use an ointment or cream rather than a lotion.</span>
                </h4 >
                <p>Ointments and creams are more effective and less irritating than lotions. Look for a cream or ointment that contains an oil such as olive oil or jojoba oil. Shea butter also works well. Other ingredients that help to soothe dry skin include lactic acid, urea, hyaluronic acid, dimethicone, glycerine, lanolin, mineral oil, and petrolatum.</p>
               <ul>
                <li>Tip: Carry a non-greasy hand cream with you, and apply it after each hand wash. This will greatly help relieve dry skin.</li>
                
               </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Wear lip balm.</span>
                </h4 >
                <p>Choose a lip balm that feels good on your lips. Some healing lip balms can irritate your lips. If your lips sting or tingle after you apply the lip balm, switch to one that does not cause this reaction.</p>
                
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Use only gentle, unscented skin care products.</span>
                </h4 >
               <p>Some skin care products are too harsh for dry, sensitive skin. When your skin is dry, stop using:</p>
               <ul>
                <li>Deodorant soaps.</li>
                <li>Skin care products that contain alcohol, fragrance, retinoid, or alpha-hydroxy acid (AHA) Avoiding these products will help your skin retain its natural oils.</li>
               </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>6. Choose non-irritating clothes and laundry detergent.</span>
                </h4 >
                <p>When our skin is dry and raw even clothes and laundry detergent can be irritating. To avoid this:</p>
              <ul>
                <li>Y Wear cotton or silk under your clothing made of wool or another material that feels rough</li>
                <li>Use laundry detergent labelled “hypoallergenic”</li>
               
              </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>7. Stay warm without cozying up to a fireplace or other heat source.</span>
                </h4 >
                <p>Sitting in front of an open flame or other heat source can dry your skin.</p>
              
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>8. Add moisture to the air.</span>
                </h4 >
                <p>Plug in a humidifier. If you can check your home heating system, find out if you have a humidifier on the system — and whether it’s working.</p>
              
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Eat a regulated balance diet.</span>
                </h4 >
                <p>Vitamins like omega fatty acids, primrose oil, Vitamin E help in maintaining a smooth supple hydrated skin.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>When to see a dermatologist</span>
                </h4 >
                <p>Your skin should start to feel better quickly. If these changes do not bring relief, you may want to see a dermatologist. Very dry skin can require a prescription ointment or cream. Dry skin also can be a sign of a skin condition that needs treatment. A dermatologist can examine your skin and explain what can help reduce your discomfort.</p>
               
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}> Certain conditions where dry skin needs attention and specialised care:</span>
                </h4 >
                <ul>
                  <li>Geriatric age group</li>
                  <li>Allergic contact dermatitis</li>
                  <li>Sensitive skin esp. facial skin which reacts to cosmetics and sunlight</li>
                  <li>Ichthyosis which is a genetic disorder of extremely dry fish like skin</li>
                  <li>Psoriasis</li>
                  <li>Patients of acne on medication</li>
                </ul>
                <p>These individuals should seek advice from a dermatologist . If not taken seriously dry skin can have consequences like severe itching, excoriations, wounds and secondary bacterial infection.</p>
                <p>Dr Sai Prasanth Neti <a href='https://drnetisclinic.com/'>best skin specialist</a></p>
            </div>
    </>
  )
}

export default Dry_Skin_Treatment_and_Prevention_Tips_from_Dermatologist